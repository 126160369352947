/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { API_TAXI, buildingCreate, buildingDelete, buildingUpdate } from '../servers/Api';
import { useBuilding } from './BuildingContext';
import { useAlert } from '../hooks/useAlert';
import { useBuildingMap } from './BuildingMapContext';

const BuildingFormContext = createContext();
export const BuildingFormContextProvider = ({ children }) => {
  const { toast } = useAlert();
  const { handleMarkerPosition } = useBuildingMap();
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [buildingId, setBuildingId] = useState(null);
  const [revenues, setRevenues] = useState([]);

  const { getBuildings } = useBuilding();

  useEffect(() => {
    getTypes();
  }, []);

  const getTypes = useCallback(async () => {
    try {
      const { data } = await API_TAXI.get('/building/type');
      setTypes(data);
    } catch (error) {
      setError(error);
    }
  }, []);

  const createBuilding = useCallback(async (markerPosition, address, name, type) => {
    setError(null);
    try {
      await buildingCreate({
        latitude: markerPosition.lat,
        longitude: markerPosition.lng,
        address,
        name,
        type,
      });
      clearForm();
      getBuildings();
    } catch (error) {
      setError(error);
    }
  }, []);

  const updateBuilding = useCallback(async (id, markerPosition, address, name, type) => {
    setError(null);
    try {
      await buildingUpdate(id, {
        latitude: markerPosition.lat,
        longitude: markerPosition.lng,
        address,
        name,
        type,
      });
      clearForm();
      getBuildings();
    } catch (error) {
      setError(error);
      clearForm();
    }
  }, []);

  const clearForm = () => {
    setName(null);
    setSelectedType(null);
    setBuildingId(null);
    setEditMode(false);
  };

  const handleEdit = (building) => {
    console.log(building);
    setName(building.name);
    handleMarkerPosition({ lat: building.location.latitude, lng: building.location.longitude });
    setSelectedType(building.type);
    setBuildingId(building.id);
    setEditMode(true);
  };

  const handleSelectedType = useCallback((e) => {
    setSelectedType(e.target.value);
  }, []);

  const handleName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const deleteBuilding = useCallback(async (buildingId) => {
    try {
      await buildingDelete(buildingId);
      await getBuildings();
      toast('Propriedade eliminada com sucesso!');
    } catch (error) {
      setError(error);
      throw error;
    }
  }, []);

  return (
    <BuildingFormContext.Provider
      value={{
        getTypes,
        types,
        selectedType,
        createBuilding,
        handleEdit,
        handleName,
        handleSelectedType,
        name,
        error,
        editMode,
        setEditMode,
        deleteBuilding,
        updateBuilding,
        buildingId,
        clearForm,
      }}
    >
      {children}
    </BuildingFormContext.Provider>
  );
};

export function useBuildingForm() {
  return useContext(BuildingFormContext);
}

BuildingFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
