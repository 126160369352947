import React from 'react';
import { PropTypes } from 'prop-types';

import { MapContainer, Marker, Polyline, TileLayer, useMap } from 'react-leaflet';

export const Map = ({ origin, destination, polyline, destinationPoint }) => {
  const defaultMapCoordenade = { lat: 14.9414817, lng: -23.4845572 };

  const ZoomToBound = ({ bounds }) => {
    const map = useMap();

    if (bounds) {
      map.fitBounds(bounds, { padding: [50, 50] });
      map.setZoom(12);
    }
    return null;
  };

  return (
    <div style={{ top: 0, left: 0, right: 0, bottom: 0 }} className="position-absolute">
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={origin || defaultMapCoordenade}
        zoom={12}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {origin && (
          <Marker draggable={false} key="origin" position={origin || defaultMapCoordenade}></Marker>
        )}
        {destinationPoint && (
          <Marker draggable={false} key="destinationPoint" position={destinationPoint}></Marker>
        )}

        {polyline.length > 0 && (
          <Polyline pathOptions={{ fillColor: 'blue' }} positions={polyline} />
        )}

        {origin && <ZoomToBound bounds={[origin]} />}
        {destination && <ZoomToBound bounds={[destination]} />}
        {destinationPoint && <ZoomToBound bounds={[destinationPoint]} />}
      </MapContainer>
    </div>
  );
};

//props validation
Map.propTypes = {
  origin: PropTypes.any,
  destination: PropTypes.any,
  destinationPoint: PropTypes.any,
  polyline: PropTypes.any,
};
