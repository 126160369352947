import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { AirPortContextProvider } from './contexts/AirPortContextProvider';
import { StepFormContextProvider } from './contexts/AirportFormDataContextProvider';
import { BuildingContextProvider } from './contexts/BuildingContext';
import { BuildingMapContextProvider } from './contexts/BuildingMapContext';
import { BuildingFormContextProvider } from './contexts/BuildingFormContext';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  debug: process.env.REACT_APP_SENTRY_DEBUG,
  release: process.env.REACT_APP_SENTRY_RELEASES,
  maxBreadcrumbs: process.env.REACT_APP_SENTRY_MAX_BREADCRUMBS,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <Provider store={store}>
    <AirPortContextProvider>
      <StepFormContextProvider>
        <BuildingContextProvider>
          <BuildingMapContextProvider>
            <BuildingFormContextProvider>
              <App />
            </BuildingFormContextProvider>
          </BuildingMapContextProvider>
        </BuildingContextProvider>
      </StepFormContextProvider>
    </AirPortContextProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
