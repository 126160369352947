import React from 'react';
import { CButton, CContainer } from '@coreui/react';
import { PropTypes } from 'prop-types';
import { useAirPort } from '../../../contexts/AirPortContextProvider';

export function Step({ formStep, currentStep, title, onClick, first, last, disabled, children }) {
  const { goBack } = useAirPort();

  return (
    <>
      {formStep === currentStep && (
        <CContainer>
          <h3>{title}</h3>
          {children}
          {first && (
            <div className="mt-4 d-grid gap-2">
              <CButton onClick={onClick} disabled={disabled}>
                Continuar
              </CButton>
            </div>
          )}

          {!first && (
            <div className="mt-4 d-flex gap-2 justify-content-md-end justify-content-between">
              <CButton onClick={goBack} color="secondary">
                Voltar
              </CButton>
              <CButton onClick={onClick} disabled={disabled}>
                {last ? 'Pagar' : 'Continuar'}
              </CButton>
            </div>
          )}
        </CContainer>
      )}
    </>
  );
}

Step.propTypes = {
  formStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  first: PropTypes.bool,
  last: PropTypes.bool,
  disabled: PropTypes.bool,
};
