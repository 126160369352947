/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { API_TAXI, getLinkInformation } from 'src/servers/Api';

const AirPortContext = createContext();

export const AirPortContextProvider = ({ children }) => {
  const [airports, setAirports] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [selectedAirportId, setSelectedAirportId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [price, setPrice] = useState(0);
  const [step, setStep] = useState(1);
  const [destinationPoint, setDestinationPoint] = useState(null);
  const [islandArea, setIslandArea] = useState(null);
  const [referralLink, setReferralLink] = React.useState(null);

  useEffect(() => {
    getAirports();
  }, []);

  useEffect(() => {
    if (selectedAirportId != null) {
      setLocations([]);
      setSelectedLocationId(null);
      setSelectedLocation(null);
      getLocations();
      const airport = airports.find((airport) => airport.id == selectedAirportId);
      setSelectedAirport(airport);
      if (airport) {
        setIsLoading(false);
      }
    }
  }, [selectedAirportId]);

  useEffect(() => {
    const response = locations.find((location) => location.id == selectedLocationId);
    setSelectedLocation(response);
    if (selectedLocationId != null) {
      getPrice();
    }
  }, [selectedLocationId, selectedLocation]);

  async function getAirports() {
    try {
      const { data } = await API_TAXI.get('/api/schedule-table/airports');
      setAirports(data);
    } catch (error) {
      setErrors(error);
    }
  }

  async function getLocations() {
    setIsLoading(true);
    try {
      const response = await API_TAXI.get(
        `/api/schedule-table/destination?airportId=${selectedAirportId}`,
      );
      setLocations(response.data);
    } catch (error) {
      setErrors(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getPrice() {
    try {
      const response = await API_TAXI.get(
        `/api/schedule-table/price?airportId=${selectedAirportId}&destinationId=${selectedLocationId}`,
      );
      setPrice(response.data);
    } catch (error) {
      setErrors(error);
    }
  }

  const goToStep = (step) => setStep(step);
  const goBack = () => setStep(step - 1);

  const getLink = async (hash) => {
    setReferralLink(hash);
    try {
      const response = await getLinkInformation(hash);
      setDestinationPoint({
        lat: response.data.location.latitude,
        lng: response.data.location.longitude,
      });
      setSelectedAirportId(response.data.airport.id);
      setSelectedAirport(response.data.airport);
      setSelectedLocationId(response.data.islandArea.id);
      setSelectedLocation(response.data.islandArea);
      setIslandArea(response.data.islandArea);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const clearState = async () => {
    setSelectedAirport(null);
    setSelectedAirportId(null);
    setLocations([]);
    setSelectedLocation(null);
    setSelectedLocationId(null);
    setIsLoading(false);
    setErrors(null);
    setPrice(0);
    setStep(1);
  };
  return (
    <AirPortContext.Provider
      value={{
        airports,
        selectedAirport,
        setSelectedAirport,
        locations,
        selectedLocation,
        setSelectedLocation,
        setSelectedLocationId,
        setSelectedAirportId,
        isLoading,
        errors,
        price,
        getPrice,
        goBack,
        goToStep,
        step,
        clearState,
        getLink,
        destinationPoint,
        islandArea,
        referralLink,
      }}
    >
      {children}
    </AirPortContext.Provider>
  );
};

export function useAirPort() {
  return useContext(AirPortContext);
}

AirPortContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
