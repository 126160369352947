/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useMap } from 'react-leaflet';

const BuildingMapContext = createContext({});

export const BuildingMapContextProvider = ({ children }) => {
  const [markerPosition, setMarkerPosition] = useState();
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    setMarkerPosition({ lat: 14.9414817, lng: -23.4845572 });
  }, []);

  useEffect(() => {
    if (markerPosition) {
      getAddressName();
    }
  }, [markerPosition]);

  const getAddressName = useCallback(() => {
    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${markerPosition.lat}&lon=${markerPosition.lng}`,
      {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setAddress(data.display_name);
      })
      .catch((err) => console.log('error in get address name', err));
  }, [markerPosition]);

  const ZoomToMarker = ({ bounds }) => {
    const map = useMap();
    if (bounds) {
      map.fitBounds(bounds);
      map.setZoom(15);
    }
    return null;
  };

  const handleMarkerPosition = useCallback((position) => {
    setMarkerPosition(position);
  }, []);
  return (
    <BuildingMapContext.Provider
      value={{
        markerPosition,
        ZoomToMarker,
        handleMarkerPosition,
        error,
        address,
      }}
    >
      {children}
    </BuildingMapContext.Provider>
  );
};

export function useBuildingMap() {
  return useContext(BuildingMapContext);
}

BuildingMapContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
