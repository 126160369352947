import swal from 'sweetalert2';

export function useAlert() {
  const toast = (msg, icon = 'success') =>
    swal.fire({
      show: true,
      title: msg,
      icon: icon,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
    });

  const confirm = async (
    confirmButtonText = 'confirmar',
    icon = 'success',
    title,
    text,
    confirButtonColor = 'green',
  ) => {
    return await swal.fire({
      show: true,
      title: title,
      text: text,
      icon: icon,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: confirButtonColor,
    });
  };

  return {
    toast,
    confirm,
  };
}
