import { useAirPort } from '../../contexts/AirPortContextProvider';

export function useAirportMap() {
  const { selectedAirport, selectedLocation } = useAirPort();

  let origin = null;
  let destination = null;
  let polyline = [];

  if (selectedAirport) {
    origin = { lat: selectedAirport.latitude, lng: selectedAirport.longitude };
  }
  if (selectedLocation) {
    destination = { lat: selectedLocation.latitude, lng: selectedLocation.longitude };
    polyline = selectedLocation.polylinePoints;
  }

  return {
    origin,
    destination,
    polyline,
  };
}
