import React, { useEffect } from 'react';
import { ResponseCard } from '../../../components/AirPort/ResponseCard';
import { usePaymentResponse } from '../../../hooks/Airport/usePaymentResponse';
import { useHistory } from 'react-router-dom';
import { useAirportFormData } from '../../../contexts/AirportFormDataContextProvider';
import { useAirPort } from '../../../contexts/AirPortContextProvider';
import { CSpinner } from '@coreui/react';
import { NHATAXI_BASE_URL } from 'src/servers/Api';

export default function AirportPayment() {
  const { reservationDate, name, lastName, email, flyNumber, contact, clearState } =
    useAirportFormData();
  const {
    price,
    selectedAirport,
    selectedLocation,
    islandArea,
    goToStep,
    referralLink,
    clearState: cS,
  } = useAirPort();
  const { message, isVisible, isSuccess } = usePaymentResponse();

  const [loading, setLoading] = React.useState(true);
  const [iframeHeigth, setIframeHeigth] = React.useState(0);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        setIframeHeigth(1000);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
          }}
        >
          <CSpinner size="xl" />
          <p className="mt-2">Carregando, por favor aguarde !</p>
        </div>
      )}
      <ResponseCard
        message={message}
        isVisible={isVisible}
        isSuccess={isSuccess}
        onClose={() => {
          history.push('/airport');
          goToStep(1);
          clearState();
          cS();
        }}
        onClick={() => history.push('/airport')}
      />

      <iframe
        width="100%"
        height={iframeHeigth}
        allow="autoplay"
        src={`${NHATAXI_BASE_URL}/api/payment/reservation?amount=${price}&airportId=${
          selectedAirport?.id
        }&destinationId=${
          selectedLocation?.id || islandArea?.id
        }&reservationDate=${reservationDate}&passengerPhone=${contact}&passengerName=${
          name + '%20' + lastName
        }&passengerEmail=${email}&flightNumber=${flyNumber}&native=false&referralLink=${referralLink}`}
      />
    </>
  );
}
