/* eslint-disable array-callback-return */
import { useState } from 'react';

export const useFilter = (data, filterTerms) => {
  const [filter, setFilter] = useState('');

  const handleSearch = (event) => {
    setFilter(event.target.value);
  };
  const lowercaseFilter = filter.toLowerCase();

  const handleFilter = (item, filter, index) =>
    item[filter[index]].toLowerCase().includes(lowercaseFilter);

  const _data = data?.filter((item) => {
    return Object.keys(item).some(() => {
      if (filterTerms?.length === 1) {
        return handleFilter(item, filterTerms, 0);
      }
      if (filterTerms?.length === 2) {
        return handleFilter(item, filterTerms, 0) || handleFilter(item, filterTerms, 1);
      }
      if (filterTerms?.length === 3) {
        return (
          item[filterTerms[0]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[1]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[2]].toLowerCase().includes(lowercaseFilter)
        );
      }
      if (filterTerms?.length === 4) {
        return (
          item[filterTerms[0]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[1]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[2]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[3]].toLowerCase().includes(lowercaseFilter)
        );
      }
      if (filterTerms?.length === 5) {
        return (
          item[filterTerms[0]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[1]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[2]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[3]].toLowerCase().includes(lowercaseFilter) ||
          item[filterTerms[4]].toLowerCase().includes(lowercaseFilter)
        );
      }
    });
  });
  return {
    data: _data,
    handleSearch,
    filter,
    setFilter,
  };
};
