import axios from 'axios';

export const WSURL = process.env.REACT_APP_WSURL;
export const NHATAXI_BASE_URL = process.env.REACT_APP_NHATAXI_BASE_URL;

const API_EXPRESS = axios.create({
  baseURL: process.env.REACT_APP_API_EXPRESS,
});

export const API_TAXI = axios.create({
  baseURL: NHATAXI_BASE_URL,
});

const user = JSON.parse(localStorage.getItem('user'));

export const userLoginEXP = (data) => API_EXPRESS.post('/login', data);

export const userRegister = (data) => API_EXPRESS.post('/register', data);

export const userLogin = (data) => API_TAXI.post('/api/authenticate/user/token', data);

export const updatePassword = (data) =>
  API_EXPRESS.post('/profile/password', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getWalletHistory = () =>
  API_TAXI.get('/api/wallet/history', {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getCurrentBalance = () =>
  API_TAXI.get('/api/wallet/balance/CLIENT', {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const renewTokenWs = () =>
  API_TAXI.get('/api/authenticate/user/token/renew', {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

export const readChatMessageAPI = (driver_id) =>
  API_TAXI.get('/api/message/user/' + driver_id, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const senChatMessageAPI = (data) =>
  API_TAXI.post('/api/message/user', data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getTripHIstory = () =>
  API_TAXI.get('/api/taxi/user/request/history', {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getTripOnroute = () =>
  API_TAXI.get('/api/taxi/user/request/ongoing', {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const postScheduleRequest = async (data) =>
  API_TAXI.post('/api/request/schedule', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getMyScheduleRequest = () =>
  API_TAXI.get('/api/request/user/schedule', {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const cancelScheduleRequest = (data) =>
  API_TAXI.put('/api/request/schedule/cancel/user', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getDefaultAddress = () =>
  API_EXPRESS.get('/api/default/address', {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  });

export const createDefaultAddress = (data) =>
  API_EXPRESS.post('/api/default/address', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  });

export const removeDefaultAddress = (id) =>
  API_EXPRESS.patch(
    '/api/default/address/' + id,
    {},
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    },
  );

export const getApiKeys = () =>
  API_EXPRESS.get('/api/api-key', {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/ld+json',
      'Content-type': 'application/json',
    },
  });

export const createNewApiKey = (data) =>
  API_EXPRESS.post('/api/api-key', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/ld+json',
      'Content-type': 'application/json',
    },
  });

export const revokeApiKey = (id) =>
  API_EXPRESS.delete('/api/api-key/' + id, {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/ld+json',
      'Content-type': 'application/json',
    },
  });

export const postDeliveries = (data) =>
  API_EXPRESS.post('/api/deliveries', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/ld+json',
      'Content-type': 'application/json',
    },
  });

export const postDeliveryPackages = (data) =>
  API_EXPRESS.post('/api/delivery_packages', data, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
  });

export const postDeliveryStatusHistories = (data) =>
  API_EXPRESS.post('/api/delivery_status_histories', data, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
  });

export const getUserData = () =>
  API_EXPRESS.get(`/api/users/${user.id}`, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
  });

export const getOpenDeliveries = (
  name,
  email,
  phone,
  delivery_status = ['pendente', 'aceite', 'em curso'],
  order = 'desc',
  before = null,
  after = null,
  page = 1,
) =>
  API_EXPRESS.get('/api/deliveries', {
    params: {
      page: page,
      delivery_status,
      'order[pickup_time]': order,
      'pickup_time[before]': before,
      'pickup_time[after]': after,
      'dropoff_contact_person.name': name,
      'dropoff_contact_person.email': email,
      'dropoff_contact_person.phone': phone,
    },
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
  });
export const getDoneDeliveries = (name, email, phone, page = 1) =>
  API_EXPRESS.get('/api/deliveries', {
    params: {
      page: page,
      delivery_status: ['realizado', 'cancelado'],
      'dropoff_contact_person.name': name,
      'dropoff_contact_person.email': email,
      'dropoff_contact_person.phone': phone,
    },
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
  });

export const cancelDelivery = (deliveryId) =>
  API_EXPRESS.patch(
    `/api/delivery/incomplete/cancel/${deliveryId}`,
    {},
    {
      headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
    },
  );

export const getUserProfile = (token) =>
  API_EXPRESS.get('/profile', {
    headers: { Authorization: `Bearer ${token}`, Accept: 'application/ld+json' },
  });

export const updateUserProfile = (data) =>
  API_EXPRESS.put('api/update-profile', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const updateAvatar = (data) =>
  API_EXPRESS.post('api/upload/avatar', data, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

export const getContactPeople = (contactPersonID) =>
  API_EXPRESS.get(`/api/contact_people/${contactPersonID}`, {
    headers: { Accept: 'application/ld+json' },
  });

export const getDelivery = (id) =>
  API_EXPRESS.get(`/api/deliveries/${id}`, {
    headers: { Accept: 'application/json' },
  });

export const getDistacePrice = (data) => API_TAXI.post('/api/integration/request/price', data);

export const getTaxiPosition = (scheduleID) =>
  API_TAXI.get(`/api/integration/request/schedule/driver/${scheduleID}`, {
    headers: {
      username: 'delivery@smartsolutions.cv',
      'x-api-key': 'OQ8pZ4tNsR4NQdy9rmHQvpARPJHDkWjbbymbs8zU',
    },
  });

export const taxiCall = (data) =>
  API_EXPRESS.post('/api/taxi_call', data, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });
export const getDeliveryRequest = (deliveryRequestID) =>
  API_EXPRESS.get(`${deliveryRequestID}`, {
    headers: { Accept: 'application/json' },
  });
export const getPrivateEndpoint = (endpoint) =>
  API_EXPRESS.get(`${endpoint}`, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });
export const getPrivateEndpointData = (endpoint) => {
  const returnPromise = new Promise(async (resolve, reject) => {
    try {
      const request = await API_EXPRESS.get(`${endpoint}`, {
        headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
      });
      resolve(request.data);
    } catch (error) {
      reject(error);
    }
  });
  return returnPromise;
};

export const getPublicEndpoint = (endpoint) =>
  API_EXPRESS.get(`${endpoint}`, {
    headers: { Accept: 'application/json' },
  });
export const postContactPerson = (data) =>
  API_EXPRESS.post('/api/contact_people', data, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });
export const completePartialDelivery = (deliveryId, data) =>
  API_EXPRESS.put(`/api/partialdelivery/complete/${deliveryId}`, data, {
    headers: { Authorization: `Bearer ${user.token}` },
  });

export const getIncompleteDeliveries = (name, email, phone, page = 1, order = 'desc') =>
  API_EXPRESS.get('/api/deliveries', {
    params: {
      page: page,
      delivery_status: ['incomplete'],
      'dropoff_contact_person.name': name,
      'dropoff_contact_person.email': email,
      'dropoff_contact_person.phone': phone,
      'order[create_at]': order,
    },
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/ld+json' },
  });

export const buildingsList = () =>
  API_TAXI.get(`/api/building`, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });

export const buildingCreate = (data) =>
  API_TAXI.post(`/api/building`, data, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });

export const buildingUpdate = (buildingId, data) =>
  API_TAXI.put(`/api/building/${buildingId}`, data, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });
export const buildingDelete = (id) =>
  API_TAXI.delete(`/api/building/${id}`, {
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });

export const revenueList = (
  buildingId = null,
  dateStart = null,
  dateEnd = null,
  page = 0,
  orderColumn,
  orderDirection,
) =>
  API_TAXI.get('/api/building/revenue', {
    params: { buildingId, dateStart, dateEnd, page, orderColumn, orderDirection },
    headers: { Authorization: `Bearer ${user.token}`, Accept: 'application/json' },
  });

export const getLinkInformation = (hash) =>
  API_TAXI.get(`/api/building/r/${hash}`, {
    headers: { Accept: 'application/json' },
  });

export const getEtaBetweenTwoCoords = (data) => API_EXPRESS.post('/api/utils/calculate-eta', data);

export const readChatMessageXpress = ({ deliveryID, context }) =>
  API_TAXI.get('/api/integration/chat/read/' + deliveryID + '/' + context);

export const sendChatMessageXpress = (data) => API_TAXI.post('/api/integration/chat', data);
