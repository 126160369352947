import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useFilter } from '../../../hooks/useFilter';
import { CCol, CFormInput, CFormLabel } from '@coreui/react';
import { useAirPort } from '../../../contexts/AirPortContextProvider';

export function DropDownSelector({ data, title, ...props }) {
  const { handleSearch, data: _data, filter, setFilter } = useFilter(data, ['name']);
  const { setSelectedLocationId, selectedLocation } = useAirPort();
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    if (selectedLocation) {
      setInputValue(selectedLocation.name);
    } else {
      setInputValue('');
    }
  }, [selectedLocation]);

  const renderSuggestions = () =>
    _data.map((item) => {
      const { name, id } = item;
      return (
        <div
          key={id}
          className="list-group-item list-group-item-action"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSelectedLocationId(id);
            setFilter('');
          }}
        >
          <strong>{name}</strong>
        </div>
      );
    });
  return (
    <CCol>
      <CFormLabel>{title}</CFormLabel>

      <CFormInput
        className="mb-2"
        onChange={(e) => {
          handleSearch(e);
          setInputValue(e.target.value);
        }}
        {...props}
        value={inputValue}
      />
      <div
        className="list-group position-absolute"
        style={{
          zIndex: 1070,
          width: 'inherit',
          height: 120,
          overflow: 'hidden',
        }}
      >
        {filter !== '' && renderSuggestions()}
      </div>
    </CCol>
  );
}

DropDownSelector.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};
