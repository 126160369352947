import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Airport from './views/pages/airport';
import AirportPayment from './views/pages/airport/AirportPayment';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const TrackOrder = React.lazy(() => import('./views/pages/track-order/TrackOrder'));
// const Airport = React.lazy(() => import('./views/pages/airport'));

class App extends Component {
  render() {
    const isLogged = localStorage.getItem('user');
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/airport" name="AirPort Page" render={(props) => <Airport />} />
            <Route
              exact
              path="/airport-payment"
              name="AirPort Page"
              render={(props) => <AirportPayment />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => (isLogged ? <DefaultLayout {...props} /> : <Login {...props} />)}
            />
            <Route
              path="/acompanhar-encomenda/:id"
              name="Track Order"
              render={(props) => <TrackOrder {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />

            <Route
              path="/"
              name="Home"
              render={(props) => (isLogged ? <DefaultLayout {...props} /> : <Login {...props} />)}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
