import { useEffect, useState } from 'react';

export function usePaymentResponse() {
  const [code, setCode] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.success === true) {
        setIsSuccess(true);
      }
      if (e.data.message) {
        setIsVisible(true);
        setMessage(e.data.message);
      }
    });
  }, []);

  return {
    code,
    message,
    isSuccess,
    isVisible,
  };
}
