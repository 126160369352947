/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { buildingsList } from '../servers/Api';

export const BuildingContext = createContext({});

export const BuildingContextProvider = ({ children }) => {
  const [buildings, setBuildings] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [error, setError] = useState(null);

  const getBuildings = async () => {
    try {
      const { data } = await buildingsList();
      setBuildings(data);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <BuildingContext.Provider
      value={{
        buildings,
        error,
        revenues,
        getBuildings,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
};

export function useBuilding() {
  return useContext(BuildingContext);
}

BuildingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
