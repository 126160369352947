import { CCol, CContainer, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React from 'react';
import { Select } from 'src/components/AirPort/Select';
import { ResumeItem } from './ResumeItem';
import { useHistory } from 'react-router-dom';
import { Step } from './Step';
import { useAirPort } from '../../../contexts/AirPortContextProvider';
import { useAirportFormData } from '../../../contexts/AirportFormDataContextProvider';
import { DropDownSelector } from '../DropDownSelector';
import { useQuery } from '../../../hooks/useQuery';
import { useState } from 'react';

export function MultiStepForm() {
  const history = useHistory();
  const {
    step,
    goToStep,
    airports,
    setSelectedAirportId,
    locations,
    setSelectedLocationId,
    selectedLocation,
    selectedAirport,
    getPrice,
    price,
    isLoading,
    islandArea,
    selectedAirportId,
  } = useAirPort();

  const {
    date,
    handleDate,
    isDateInvalid,
    time,
    handleTime,
    isTimeInvalid,
    flyNumber,
    setFlyNumber,
    email,
    setEmail,
    name,
    setName,
    lastName,
    setLastName,
    contact,
    setContact,
  } = useAirportFormData();
  const query = useQuery();
  const [validEmail, setValidEmail] = useState(false);
  const disabledContinueOnFirstStep = () => {
    if (query.get('r')) {
      return false;
    } else if (!selectedLocation || !selectedLocation) {
      return true;
    }
  };

  //react email validation

  function isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  const handleChange = (event) => {
    if (isValidEmail(event.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }

    setEmail(event.target.value);
  };

  //validate name

  return (
    <CForm className="multi-step-container">
      <Step
        currentStep={step}
        formStep={1}
        title="Dados de Localização"
        first
        onClick={() => goToStep(2)}
        disabled={disabledContinueOnFirstStep()}
      >
        <CRow xs={{ cols: 1 }} md={{ cols: 2 }} className="mt-4">
          <CCol>
            {query.get('r') ? (
              <>
                <CFormLabel>Origem*</CFormLabel>
                <CFormInput value={selectedAirport?.name} disabled />
              </>
            ) : (
              <Select
                title="Origem*"
                options={airports}
                label="Selecionar Origem"
                onChange={(e) => setSelectedAirportId(e.target.value)}
              />
            )}
          </CCol>
          <CCol>
            {query.get('r') ? (
              <>
                <CFormLabel>Destino*</CFormLabel>
                <CFormInput value={islandArea?.name} disabled />
              </>
            ) : (
              <DropDownSelector
                data={locations}
                disabled={!selectedAirport || isLoading}
                title={isLoading ? 'Carregando destinos ...' : 'Destino'}
                placeholder="Pesquisar Destino"
              />
            )}
          </CCol>
        </CRow>
      </Step>

      <Step
        currentStep={step}
        formStep={2}
        title="Dados de Reserva"
        onClick={() => goToStep(3)}
        disabled={isDateInvalid || isTimeInvalid || !flyNumber}
      >
        <CRow>
          <CCol xs={12} className="mt-4">
            <CFormLabel>Nº do voo*</CFormLabel>
            <CFormInput
              onChange={(e) => setFlyNumber(e.target.value)}
              value={flyNumber}
              invalid={flyNumber.trim().length <= 0}
              valid={flyNumber}
            />
          </CCol>
        </CRow>
        <CRow xs={{ cols: 1 }} md={{ cols: 2 }}>
          <CCol className="mt-4">
            <CFormLabel>Data*</CFormLabel>
            <CFormInput
              type="date"
              id="date"
              invalid={isDateInvalid}
              className="mb-2"
              value={date}
              onChange={handleDate}
            />
          </CCol>
          <CCol className="mt-4">
            <CFormLabel>Hora de chegada*</CFormLabel>
            <CFormInput
              type="time"
              id="time"
              invalid={isTimeInvalid}
              value={time}
              onChange={handleTime}
            />
          </CCol>
        </CRow>
      </Step>

      <Step
        currentStep={step}
        formStep={3}
        title="Dados de Passageiro"
        disabled={!name || !lastName || !email || !contact}
        onClick={() => {
          getPrice();
          goToStep(4);
        }}
      >
        <CRow xs={{ cols: 1 }} md={{ cols: 2 }}>
          <CCol className="mt-4">
            <CFormLabel>Nome*</CFormLabel>
            <CFormInput
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
              invalid={name.trim().length <= 0}
              valid={name}
            />
          </CCol>
          <CCol className="mt-4">
            <CFormLabel>Apelido*</CFormLabel>
            <CFormInput
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              required
              invalid={lastName.trim().length <= 0}
              valid={lastName}
            />
          </CCol>

          <CCol className="mt-4">
            <CFormLabel>E-mail* </CFormLabel>
            <CFormInput
              type="email"
              onChange={handleChange}
              required
              value={email}
              invalid={!validEmail}
              valid={validEmail}
            />
          </CCol>

          <CCol className="mt-4">
            <CFormLabel>Contacto*</CFormLabel>
            <CFormInput
              onChange={(e) => setContact(e.target.value)}
              required
              value={contact}
              type="number"
              invalid={contact.trim().length !== 7}
              valid={contact.trim().length === 7}
            />
          </CCol>
        </CRow>
      </Step>

      <Step currentStep={step} formStep={4} onClick={() => history.push('/airport-payment')} last>
        <CContainer>
          <div className="d-flex gap-2 justify-content-between align-items-center mb-4">
            <h3>Resumo</h3>
            <div>
              <span>Total a pagar</span>
              <h2>{price} CVE</h2>
            </div>
          </div>
          <CRow xs={{ cols: 2 }} md={{ cols: 3 }}>
            <ResumeItem title="Origem" value={selectedAirport && selectedAirport.name} />
            <ResumeItem
              title="Destino"
              value={query.get('r') ? islandArea?.name : selectedLocation && selectedLocation.name}
            />
            <ResumeItem title="E-mail" value={email} />
            <ResumeItem title="Nº do voo" value={flyNumber} />
            <ResumeItem title="Data" value={date} />
            <ResumeItem title="Hora de chegada" value={time} />
            {/*<ResumeItem title="Contacto" value={contact} />*/}
          </CRow>
        </CContainer>
      </Step>
    </CForm>
  );
}
