import React from 'react';
import PropTypes from 'prop-types';
import { CSpinner } from '@coreui/react';

export function Loader({ msg = 'Carregando, por favor aguarde !' }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <CSpinner size="xl" />
      <p className="mt-2">{msg}</p>
    </div>
  );
}

//props validation
Loader.propTypes = {
  msg: PropTypes.string,
};
