import React, { forwardRef } from 'react';
import { PropTypes } from 'prop-types';
import { CFormSelect, CFormLabel } from '@coreui/react';
export const Select = forwardRef(({ title, options, label, ...restProps }, ref) => {
  return (
    <div>
      <CFormLabel>{title}</CFormLabel>
      <CFormSelect
        size="md"
        className="mb-3"
        aria-label="Large select example"
        ref={ref}
        {...restProps}
      >
        {label && <option>{label}</option>}
        {options.map((item, index) => (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        ))}
      </CFormSelect>
    </div>
  );
});

//props validation
Select.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};
// react display name
Select.displayName = 'Select';
