import React, { useEffect } from 'react';
import { useAirportMap } from '../../../hooks/Airport/useAirportMap';
import { useQuery } from '../../../hooks/useQuery';
import { Map } from '../../../components/AirPort/Map';
import { useAirPort } from '../../../contexts/AirPortContextProvider';
import { MultiStepForm } from '../../../components/AirPort/MultiStepForm';
import { Loader } from 'src/components/Loader';
import { useState } from 'react';
import { useReducer } from 'react';

export default function Airport() {
  const { origin, destination, polyline } = useAirportMap();
  const { getLink, destinationPoint, isLoading } = useAirPort();

  const query = useQuery();

  useEffect(() => {
    if (Boolean(query.get('r'))) {
      getLink(query.get('r'));
    }
  }, []);
  if (Boolean(query.get('r'))) {
    if (isLoading) {
      return <Loader />;
    }
  }
  return (
    <>
      <Map
        origin={origin}
        destination={destination}
        polyline={polyline}
        destinationPoint={destinationPoint}
      />
      <div className="airport-container position-absolute">
        <MultiStepForm />
      </div>
    </>
  );
}
