/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { format, parseISO } from 'date-fns';

const StepFormContext = createContext();

export const StepFormContextProvider = ({ children }) => {
  const [reservationDate, setReservationDate] = useState(null);
  const [errors, setErrors] = useState(null);
  const [date, setDate] = useState(null);
  const [isDateInvalid, setIsDateInvalid] = useState(true);
  const [time, setTime] = useState(null);
  const [isTimeInvalid, setIsTimeInvalid] = useState(true);
  const [flyNumber, setFlyNumber] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contact, setContact] = useState('');

  let formattedDate;

  useEffect(() => {
    if (date) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      formattedDate = format(parseISO(date), 'dd-MM-yy');
    }

    setReservationDate(formattedDate + ' ' + time);
  }, [time, date]);

  const handleDate = (e) => {
    const dateValue = e.target.value;

    setDate(dateValue);
    const selectedDate = new Date(`${dateValue} 00:00`);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() < today.getTime()) {
      setIsDateInvalid(true);
    } else {
      setIsDateInvalid(false);
    }
  };

  const handleTime = (e) => {
    setTime(e.target.value);
    if (date) {
      const selectedTime = new Date(`${date} ${e.target.value}`);
      const nowTime = new Date();
      if (selectedTime - nowTime < 19 * 60 * 1000) {
        setIsTimeInvalid(true);
      } else {
        setIsTimeInvalid(false);
      }
    }
  };

  const clearState = async () => {
    setReservationDate(null);
    setErrors(null);
    setDate(null);
    setIsDateInvalid(true);
    setTime(null);
    setIsTimeInvalid(true);
    setFlyNumber('');
    setEmail('');
    setName('');
    setLastName('');
    setContact('');
  };
  return (
    <StepFormContext.Provider
      value={{
        errors,
        date,
        isDateInvalid,
        setIsDateInvalid,
        time,
        isTimeInvalid,
        setIsTimeInvalid,
        flyNumber,
        setFlyNumber,
        email,
        setEmail,
        name,
        setName,
        lastName,
        setLastName,
        contact,
        setContact,
        reservationDate,
        handleDate,
        handleTime,
        clearState,
      }}
    >
      {children}
    </StepFormContext.Provider>
  );
};

export function useAirportFormData() {
  return useContext(StepFormContext);
}

StepFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
