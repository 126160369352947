import React from 'react';
import { PropTypes } from 'prop-types';
import { CCol } from '@coreui/react';

export const ResumeItem = ({ title, value }) => {
  return (
    <CCol className="d-flex flex-column card-header">
      <b>{title}</b>
      <span>{value}</span>
    </CCol>
  );
};

//props validation
ResumeItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
